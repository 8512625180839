import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Cookie() {

  const [visible, setVisible] = useState(localStorage.getItem("cookie") ? false : true);

  const accept = ()=>{
    localStorage.setItem("cookie", "accepted");
    setVisible(false)
  }

  const cancel = ()=>{
    localStorage.removeItem("cookie")
    setVisible(false)
  }

  return (
    <div className={`cookie-container ${ !visible ? 'close' : '' }`}>
        <div className='cookie-inner-box'>
            <p className='cookie-title'>Datenschutzeinstellungen</p>
            <p className='cookie-text'>
             Auf dieser Webseite kommen einwilligungsbedürftige Cookies zum Einsatz.<br />
             Nähere Informationen finden Sie in den <Link to={"/cookie-settings"}>Cookie-Einstellungen</Link>. Sie können Ihre Einwilligung in der <Link to={"/privacy"}>Datenschutzerklärung</Link> jederzeit anpassen oder widerrufen.
            </p>
            <button className='cookie-btn cookie-btn-primary' onClick={accept}>Akzeptieren</button>
            <button className='cookie-btn' onClick={cancel}>Ablehnen</button>
        </div>
    </div>
  )
}
