import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

export default function ProgessPageLoading() {

    const URL_LOCATION = useLocation();

    const [open, setOpen] = useState(false)

    useEffect(()=>{
      setOpen(true);

      setTimeout(()=>{
        setOpen(false);
      }, 1000)

    }, [URL_LOCATION])

  return (
    <>
    { open && (
    <div className='progress-bar'></div>
    ) }
    </>
  )
}
