import React, { useEffect, lazy, Suspense } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SplashScreen from "./components/SplashScreen"
import Cookie from "./components/Cookie"
import Header from "./components/Header"
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init({ duration: 1000 });


function App() {

  const location = useLocation();

  const Home = lazy(() => import("./pages/Home"))
  const Websites = lazy(() => import("./pages/Websites"))
  const Apps = lazy(() => import("./pages/Apps"))
  const Logos = lazy(() => import("./pages/Logos"))
  const About = lazy(() => import("./pages/About"))
  const Contact = lazy(() => import("./pages/Contact"))
  const Imprint = lazy(() => import("./pages/Imprint"))
  const Privacy = lazy(() => import("./pages/Privacy"))
  const NotFound404 = lazy(() => import("./pages/NotFound404"))
  const CookieSettings = lazy(() => import("./pages/CookieSettings"))

  useEffect(()=>{
   window.scrollTo(0, 0)
  }, [location])

  return (
    <>
      
      <SplashScreen />
      <Cookie />
      <Header />

      <Suspense fallback={<h2>Anwendung wird geladen ...</h2>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/websites" element={<Websites />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/logos" element={<Logos />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookie-settings" element={<CookieSettings />} />
          <Route path="*" element={<NotFound404 />} />
      </Routes>
     </Suspense>     

    </>
  );
}

export default App;
