import React, { useEffect, useState } from "react"
import { Link, NavLink } from "react-router-dom"
import ProgessPageLoading from "./ProgessPageLoading";
import { IoArrowForward, IoMenuOutline, IoMoonOutline, IoSunnyOutline } from "react-icons/io5";

export default function Header() {

  const [dark, setDark] = useState(false);

  useEffect(()=>{
    if(!localStorage.getItem("dark")){
      localStorage.setItem("dark", "false")
    }else {
     if(localStorage.getItem("dark") === "true"){
      setDark(true);
     }
    }
  }, [])

  useEffect(()=>{
    if(dark){
      localStorage.setItem("dark", "true")
      document.body.classList.add("dark")
    }else {
      localStorage.setItem("dark", "false")
      document.body.classList.remove("dark")
    }
  }, [dark])

  useEffect(()=>{
    
    const removeOpenAttr = e => {
      document.addEventListener("click", e => {
        if(e.target.parentNode?.className !== "dropdown"){
          document.querySelector(".dropdown").removeAttribute("open")
        }
      })
    }
    
    document.addEventListener("click", removeOpenAttr)

    return ()=>{
      document.removeEventListener("click", removeOpenAttr)
    }

  }, [])

  return (
    <>

    <div className="navbar">
     <Link to={"/"} className="logo">
      <picture>
        <source media="(max-width: 1023px)" srcSet={require("../assets/logo-big-mobile.webp")} />
        <source media="(min-width: 1023px)" srcSet={require("../assets/logo-big.webp")} />
        <img src={require("../assets/logo-big.webp")} width={60} height={40} alt='logostart' className="logo-img" style={{ marginTop: 5 }} />
      </picture>
      <p>VIPSDESIGN</p>
     </Link>
     <div className="navbar-gridbox">
      
      <div className="navbar-links">

      <NavLink to={"/"} className="start-link">STARTSEITE<div className="line"></div></NavLink>
      <NavLink to={"/websites"} className="start-link">WEBSITE'S<div className="line"></div></NavLink>
      <NavLink to={"/apps"} className="start-link">APP'S<div className="line"></div></NavLink>
      <NavLink to={"/logos"} className="start-link">LOGO'S<div className="line"></div></NavLink>

      <details className="dropdown">

        <summary>
          MEHR
        </summary>
        
        <div className="more-box">
          <NavLink to={"/about"} className="more-box-link">ÜBER UNS<IoArrowForward size={15} /></NavLink>
          <NavLink to={"/contact"} className="more-box-link">KONTAKT<IoArrowForward size={15} /></NavLink>
          <NavLink to={"/imprint"} className="more-box-link">IMPRESSUM<IoArrowForward size={15} /></NavLink>
          <NavLink to={"/privacy"} className="more-box-link">DATENSCHUTZ<IoArrowForward size={15} /></NavLink>
        </div>

      </details>

      </div>

      <div className="navbar-button" onClick={()=>setDark(!dark)}>
      { dark ? <IoSunnyOutline size={27} /> : <IoMoonOutline size={25} /> }
      </div>

      <div className="navbar-button menu-open-btn" onClick={()=>{document.querySelector(".menu-rgba").classList.add("active"); document.querySelector(".menu-container").classList.add("active")}}>
        <IoMenuOutline />
      </div>

     </div>
    </div>

   <div className="menu-rgba" onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}></div>

    <div className="menu-container">
      <div>

      <div className="menu-header">
      <img src={require("../assets/logo-big.webp")} width={60} height={40} alt='logo' className="logo-img" loading="lazy" style={{ marginTop: 5 }} />
      <p>VIPSDESIGN<br /><label>ONE DESIGN FOREVER</label></p>
      </div>
      <NavLink to={"/"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>STARTSEITE<div className="line"></div></NavLink>
      <NavLink to={"/websites"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>WEBSITE'S<div className="line"></div></NavLink>
      <NavLink to={"/apps"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>APP'S<div className="line"></div></NavLink>
      <NavLink to={"/logos"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>LOGO'S<div className="line"></div></NavLink>
      <NavLink to={"/about"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>ÜBER UNS<div className="line"></div></NavLink>
      <NavLink to={"/contact"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>KONTAKT<div className="line"></div></NavLink>
      <NavLink to={"/imprint"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>IMPRESSUM<div className="line"></div></NavLink>
      <NavLink to={"/privacy"} onClick={()=>{document.querySelector(".menu-rgba").classList.remove("active"); document.querySelector(".menu-container").classList.remove("active")}}>DATENSCHUTZ<div className="line"></div></NavLink>
     
      </div>

      <div style={{ padding: 20 }}>
      <p className="menu-info">Rühlweg 34,<br />45470 Mülheim an der Ruhr</p>
      <p className="menu-info" onClick={()=>window.location.href="tel:020899840585"}>0208 99840585</p>
      </div>

    </div>

    <ProgessPageLoading />

    </>
  )
}
