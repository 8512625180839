import React from 'react'

export default function SplashScreen() {
  return (
    <div className='splashscreen-container'>
      <picture>
        <source media="(max-width: 1023px)" srcSet={require("../assets/logo-big2-mobile.webp")} />
        <source media="(min-width: 1023px)" srcSet={require("../assets/logo-big2.webp")} />
        <img src={require("../assets/logo-big2.webp")} width={120} height={80} style={{ marginRight: 20 }} />
      </picture>
      <p>VIPSDESIGN</p>
    </div>
  )
}
